import { Component, ViewChild, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { NgForm} from '@angular/forms';
import { TweenLite, Power3 } from 'gsap/all';
import { HttpService } from '../../../../http.service';
import { configuration } from '../configuration.data';
import { WIFI_PRICE } from '../../../../../appConfig';

@Component({
  selector: 'app-view3d-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  providers: [HttpService]
})
export class QuoteComponent implements OnInit {

  @ViewChild('page') page: ElementRef;
  @ViewChild('wifi') wifi: ElementRef;
  @Output() generate = new EventEmitter<string>();

  data: any = configuration;
  battensFinish: string;
  frameFinish: string;
  powerMeFinish: string;
  wifiPrice = Math.round(WIFI_PRICE / 100);

  ngOnInit() {}

  public show(): void {

    this.battensFinish = addNewlines(configuration.battensFinish);
    this.frameFinish = addNewlines(configuration.frameFinish);
    this.powerMeFinish = addNewlines(configuration.powerMeFinish);

    if (configuration.powerMeFinish === 'N/A') {
      this.wifi.nativeElement.setAttribute('disabled', 'true');
    } else {
      this.wifi.nativeElement.removeAttribute('disabled');
      if (this.wifi.nativeElement.checked) {
        configuration.price += this.wifiPrice;
      }
    }
    this.page.nativeElement.style.display = 'block';

    TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power3.easeOut,
      opacity: 1
    });
  }

  public hide(): void {

    TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power3.easeOut,
      opacity: 0,
      onComplete: () => { this.page.nativeElement.style.display = 'none'; }
    });
  }

  public changePrice(event) {

    if (event.target.checked) {
      configuration.price += this.wifiPrice;
    } else {
      configuration.price -= this.wifiPrice;
    }
  }

  public submit(form: NgForm) {
    this.generate.emit();
    this.hide();
  }
}

function addNewlines(str) {

  return str.replace(/,/g, '\n');
}

/*
arms (int): Number of arms ======> getAmountOfArms()
partsCode (string): Parts code ======> getSequence() / 2d
battensFinish (string): Comma separated list of batten finishes
frameFinish (string): Frame finish
powerMeFinish (string): PowerMe Finish (use "N/A" if no powerMes).
price (string): Fully formatted price in dollars (eg. $123.45) =====> getPrice()
Dimensions: Dimensions string as displayed in-app ====> 2d
salesName: Name of sales team member =====> home.ts
salesPhone (string): Phone number of sales team member =====> home.ts
powerMePositions (array<string>): Array of powerMe positions, 1 entry for each powerMe ====> 2d
image2D (data): screenshot of 2D view  ====> 2d
image3D (data): screenshot of 3D view  ====> captureScene()
 */
