import { Component, ViewChild, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { NgForm} from '@angular/forms';
import { TweenLite, Power3 } from 'gsap/all';
import { HttpService } from '../../../../http.service';
import { configuration } from '../configuration.data';

@Component({
  selector: 'app-view3d-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  providers: [HttpService]
})
export class EmailComponent implements OnInit {

  @ViewChild('page') page: ElementRef;
  @ViewChild('msg') elMsg: ElementRef;
  @ViewChild('state') state: ElementRef;
  @ViewChild('overlay') overlay: ElementRef;
  @Output() request = new EventEmitter<string>();

  codes: string;
  message: string;
  image: File;

  constructor(private httpService: HttpService) {}

  ngOnInit() {

    this.elMsg.nativeElement.addEventListener('touchmove', event => {
      event.stopPropagation();
    }, false);

    this.state.nativeElement.addEventListener('change', event => {
      this.overlay.nativeElement.style.display = 'none';
    }, false);

    this.page.nativeElement.addEventListener('keydown', (event) => { event.stopPropagation(); }, true);
  }

  public show(): void {

    this.page.nativeElement.style.display = 'block';
    TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power3.easeOut,
      opacity: 1
    });
  }

  public hide(): void {

    TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power3.easeOut,
      opacity: 0,
      onComplete: () => { this.page.nativeElement.style.display = 'none'; }
    });
  }

  submit(form: NgForm) {

    this.hide();

    const formData = new FormData();
    Object.entries(form.value).forEach(
      ([key, value]: any[]) => {
        formData.append(key, value);
      });

    formData.append('image', this.image);
    formData.append('battensFinish', configuration.battensFinish);
    formData.append('frameFinish', configuration.frameFinish);
    formData.append('powerMeFinish', configuration.powerMeFinish);

    // this.httpService.post('https://postman-echo.com/post', formData)

    this.httpService.post('save', formData)
      .subscribe( (data: any) => {
          console.log(data);
          this.request.emit('success');
        }, error => {
          this.request.emit('error');
          console.error(error);
      });
  }
}
