export const configuration: any = {
  arms: 0,
  partsCode: '',
  battensFinish: '',
  frameFinish: '',
  powerMeFinish: '',
  price: '',
  dimensions: '',
  salesName: '',
  salesPhone: '',
  powerMePositions: [],
  image2D: null,
  image3D: null
};
