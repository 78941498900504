export const initSequences: any[] = [
  { angle: 0, codes: '3S'},
  { angle: 0, codes: '3B'},
  { angle: 0, codes: '2S'},
  { angle: 0, codes: '2B'},
  { angle: 0, codes: '1S'},
  { angle: 0, codes: '1B'},
  { angle: 0, codes: '2S-C180'},
  { angle: 0, codes: '2B-C180'},
  { angle: 0, codes: '1S-1S-1S'},
  // { angle: 0, codes: '1S-1S-PM-1S'},
  { angle: 0, codes: '1S-1S-C180-1S'},
  // { angle: 0, codes: '2S-PM-1S'},
  { angle: 0, codes: '2S-C180-2SR'},
  // { angle: 0, codes: '2S-PM-2S'},
  { angle: 0, codes: '2S-C45-2B'},
  { angle: 0, codes: '2S-C90-2B'},
  { angle: 0, codes: '2S-C90R-2S-C90R-2S-C90R-2S-C90R'},
  { angle: 0, codes: '3B-C90-3B-C90-3B-C90-3B-C90'},
  // { angle: 45, codes: '2S-PM-C90-2B-PMR-2SR-C90R-PMR-2B'},
  { angle: 45, codes: '2B-C90R-2B-C90R-2B-C90-2B-C90-2B'}
];
