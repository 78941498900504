import { Component, ViewChild, ElementRef, Output, EventEmitter, OnInit, QueryList, Renderer2, ApplicationRef } from '@angular/core';
import { TweenLite, Power3 } from 'gsap/all';
import { Cfg2d } from './configurator2d/js/Cfg2d';
import { parts } from './configurator2d/js/data';
import { CODES } from './configurator2d/js/types';
import { loadData } from './configurator2d/js/loader';
import { View3dComponent } from './view3d/view3d.component';
import { ContactComponent } from '../../contact/contact.component';
import { TutorialComponent } from '../tutorial/tutorial.component';
import { touchController } from '../touchController';
import cssVars from 'css-vars-ponyfill';
import { path } from '../../../assets/js/urls';

enum Subtitle {
  Size,
  Codes,
  Quote
}

@Component({
  selector: 'app-connector',
  template: `
    <article class="page" #page>
      <section class="header" #header>
        <div class="arrow" (click)=hide()>&#xe907;</div>
        <span class="title">configure my aria</span><br>
        <span class="codes" #codes (click)=changeSubtitle()>1S-2S-3S</span>
      </section>
      <section class="aside" #aside>
        <div class="splitter">
          <div class="tag drawer-close" #tag (click)=toggleAside()></div>
          <div class="line"></div>
        </div>
        <div class="wrapper">
            <div class="parts" #partsList></div>
        </div>
      </section>
      <div class="button" #button (click)=show3d()>view in 3D</div>
      <div class="bottom-spots">
        <div class="spot contact" #balloon data-title="Enquire&nbsp;Now" (click)=showContact()></div>
        <div class="spot help" data-title="Help" (click)="help()"></div>
      </div>
      <div class="bin" #bin></div>
    </article>
    <app-tutorial></app-tutorial>
    <app-contact></app-contact>
    <app-view3d (showConnector)="slide()"></app-view3d>
  `,
  styleUrls: ['./connector.component.scss']
})

export class ConnectorComponent implements OnInit {

  @ViewChild('page') page: ElementRef;
  // @ViewChild('main') main: ElementRef;
  @ViewChild('aside') aside: ElementRef;
  @ViewChild('header') header: ElementRef;
  @ViewChild('button') button: ElementRef;
  @ViewChild('balloon') balloon: ElementRef;
  @ViewChild('bin') bin: ElementRef;
  @ViewChild('tag') tag: ElementRef;
  @ViewChild('codes') codes: ElementRef;
  @ViewChild('partsList') partsList: ElementRef;
  @ViewChild(ContactComponent) contact;
  @ViewChild(View3dComponent) view3d;
  @ViewChild(TutorialComponent) tutorial;
  @Output() showConnector = new EventEmitter<void>();

  initialSequence: any;
  cfg2d: any = null;
  loadedPromise: any = null;
  mode: number = Subtitle.Size;

  constructor(private renderer: Renderer2, private appRef: ApplicationRef) { cssVars(); }

  ngOnInit() {

    window.addEventListener('resize', () => {
      if (this.cfg2d !== null ) {
        this.cfg2d.scaleAndAlign();
      }
    });

    touchController(this.partsList.nativeElement);
    this.loadedPromise = loadData(path);

    this.loadedPromise.then(() => {

      for (const tp in parts) {

        if (!parts.hasOwnProperty(tp)) {
          continue;
        }

        const part = parts[tp];

        const set = this.renderer.createElement('div');
        this.renderer.appendChild(this.partsList.nativeElement, set);
        this.renderer.addClass(set, 'set');

        const factor = 0.5;

        set.style.width = factor * part.width + 'px';
        set.style.height = factor * part.height + 'px';

        const initialSequence = CODES[tp]; // [{type: tp, turned: false}];
        const callbacks = {
          msDown: (link, event) => {
            this.cfg2d.addLink(link.type, event);
            this.hideElements();
          }
        };

        const cfg2d = new Cfg2d(document, set, callbacks, parts, initialSequence, 0, false, false);

        const text = this.renderer.createElement('div');
        text.innerText = initialSequence;
        this.renderer.addClass(text, 'caption');
        this.renderer.appendChild(set, text);
      }
    });

    this.page.nativeElement.addEventListener('mouseup', () => { this.showElements(); }, false );
  }

  public show(): void {

    const scene = this.page.nativeElement;

    this.loadedPromise.then(() => {

      if (this.cfg2d !== null) {
        this.cfg2d.removeLinks();
      }

      const callbacks = {

        msClick: (link) => { /*console.log(link.chain.getSet());*/
        },
        msDown: (link, event) => {
          this.hideElements();
          /*console.log(link.type);*/
        },
        endDrag: (link) => {
          this.showElements();
          // console.log('END DRAGGING'); console.log(link.chain.getCodes());
          this.codes.nativeElement.textContent = this.getSubtitle(this.mode, link.chain);
        }
      };

      this.cfg2d = new Cfg2d(document, scene, callbacks, parts, this.initialSequence.codes, this.initialSequence.angle, true, false);

      this.codes.nativeElement.textContent = this.getSubtitle(this.mode, this.cfg2d.chain);
    });

    this.slide();
  }

  public slide(): void {

    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: 0, onComplete: () => {
      this.toggleAside(true);
      // this.tutorial.run(0);
    }});
  }
  public help(): void {
    this.tutorial.firstTime = true;
    this.tutorial.run(0);
  }

  show3d(): void {

    this.view3d.cfg2d = this.cfg2d;
    this.view3d.show();
    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '-50%' });
    this.view3d.sequence = this.cfg2d.getSet();
    // console.log(this.cfg2d.getSet());
  }

  hide(): void {

    this.showConnector.emit();
    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '100%', onComplete: () => { this.toggleAside(false); } });
  }

  toggleAside(open?): void {

    const strOpen = 'drawer-open';
    const strClose = 'drawer-close';

    let styleRight = '-256px';

    const asideEl = this.aside.nativeElement;
    const tagEl = this.tag.nativeElement;

    if (open === undefined) {
      open = !tagEl.classList.contains(strOpen);
    }

    if ((tagEl.classList.contains(strOpen) && open) || (tagEl.classList.contains(strClose) && !open)) { return; }

    if (asideEl.parentElement.offsetWidth - asideEl.offsetLeft - asideEl.offsetWidth < 0 && open) {
      styleRight = '0px';
    }

    this.tag.nativeElement.classList.toggle(strOpen);
    this.tag.nativeElement.classList.toggle(strClose);

    TweenLite.to(asideEl, 0.4, { ease: Power3.easeOut, right: styleRight});
  }

  public changeSubtitle(): void {

    if (this.mode < Subtitle.Codes) {
      this.mode++;
    } else {
      this.mode = Subtitle.Size;
    }
    this.codes.nativeElement.textContent = this.getSubtitle(this.mode, this.cfg2d.chain);
  }

  private getSubtitle(mode, chain): string {

    let sub: string;

    switch (mode) {
      case Subtitle.Size:
        sub = chain.getRealSize().text;
        break;
      case Subtitle.Codes:
        sub = chain.getCodes();
        break;
      case Subtitle.Quote:
        sub = 'nothing';
        break;
    }
    return sub;
  }

  public showContact() {

    this.contact.codes = this.cfg2d.chain.getCodes();
    this.contact.show();
  }

  private hideElements() {

    this.balloon.nativeElement.style.visibility = 'hidden';
    this.button.nativeElement.style.visibility = 'hidden';
    this.aside.nativeElement.style.visibility = 'hidden';
    this.header.nativeElement.style.visibility = 'hidden';
    this.bin.nativeElement.style.visibility = 'visible';

    this.balloon.nativeElement.style.display = 'none';
    this.button.nativeElement.style.display = 'none';
    this.aside.nativeElement.style.display = 'none';
    this.header.nativeElement.style.display = 'none';
    this.bin.nativeElement.style.display = 'block';
  }

  private showElements() {

    this.balloon.nativeElement.style.visibility = 'visible';
    this.button.nativeElement.style.visibility = 'visible';
    this.aside.nativeElement.style.visibility = 'visible';
    this.header.nativeElement.style.visibility = 'visible';
    this.bin.nativeElement.style.visibility = 'hidden';

    this.balloon.nativeElement.style.display = 'block';
    this.button.nativeElement.style.display = 'block';
    this.aside.nativeElement.style.display = 'flex';
    this.header.nativeElement.style.display = 'block';
    this.bin.nativeElement.style.display = 'none';
  }
}
