export function touchController(element) {

  let y: number;

  element.addEventListener('touchstart', event => {

    y = event.changedTouches[0].pageY;
  }, false);

  element.addEventListener('touchmove', event => {

    const elementBounding = element.getBoundingClientRect();
    const elementTop = elementBounding.top;
    const elementBottom = elementBounding.bottom;

    const parentBounding = element.parentElement.getBoundingClientRect();
    const parentTop = parentBounding.top;
    const parentBottom = parentBounding.bottom;

    const deltaTop = Math.round(parentTop - elementTop);
    const deltaBottom = Math.round(elementBottom - parentBottom);

    const touch = event.changedTouches[0];

    if (deltaTop > 0 && deltaBottom > 0) {
      event.stopPropagation();
    } else {
      if ((deltaTop === 0 && y > touch.pageY) || (deltaBottom === 0 && y < touch.pageY)) {
        event.stopPropagation();
      }
    }
    y = event.changedTouches[0].pageY;

  }, false);
}
