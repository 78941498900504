import {Component, ViewChild, ElementRef, Renderer2, OnInit} from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <article class="wrapper" #wrapper>
        <app-home></app-home>
    </article>
  `,
  styles: [`
    .wrapper {
      position: absolute;
      text-align: center;
      width: 100%;
      height: 100%;
      min-width: 900px;
      min-height: 600px;
      top: 0;
      background-color: #000;
      overflow: hidden;
    }
` ]
})
export class AppComponent implements OnInit {

  @ViewChild('wrapper') wrapper: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {

    window.addEventListener('contextmenu', event => {
      event.preventDefault();
    }, false);

    this.wrapper.nativeElement.addEventListener('touchmove', event => {
      if ( event.cancelable ) {
        event.preventDefault();
        //console.log('stop on app');
      }
    }, false);
  }
}
