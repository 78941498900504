import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NgForm} from '@angular/forms';
import { TweenLite, Power3 } from 'gsap/all';
import { HttpService } from '../http.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [HttpService]
})
export class ContactComponent implements OnInit {

  @ViewChild('page') page: ElementRef;
  @ViewChild('msg') elMsg: ElementRef;
  @ViewChild('state') state: ElementRef;
  @ViewChild('overlay') overlay: ElementRef;

  codes: string;
  message: string;

  constructor(private httpService: HttpService) {}

  ngOnInit() {

    this.elMsg.nativeElement.addEventListener('touchmove', event => {

      // console.log('MOVE');
      event.stopPropagation();
    }, false);

    this.state.nativeElement.addEventListener('change', event => {
      this.overlay.nativeElement.style.display = 'none';
    }, false);

    this.page.nativeElement.addEventListener('keydown', (event) => { event.stopPropagation(); }, true);
  }

  public show(): void {

    this.page.nativeElement.style.display = 'block';
    TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power3.easeOut,
      opacity: 1
    });
  }

  public hide(): void {

    TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power3.easeOut,
      opacity: 0,
      onComplete: () => { this.page.nativeElement.style.display = 'none'; }
    });
  }

  submit(form: NgForm) {

    this.hide();
    console.log(form.value);
    this.httpService.post('contact', form.value)
      .subscribe( (data) => {
          console.log(data);
        }, error => {
          console.error(error);
      });
  }
}
