import { Component, ViewChild, ViewChildren, ElementRef, QueryList, AfterViewInit, ApplicationRef } from '@angular/core';
import { TweenLite, Power1, Power3 } from 'gsap/all';
import * as Swiper from '../../../../node_modules/swiper/dist/js/swiper.min.js'

@Component({
  selector: 'app-tutorial',
  template: `
    <div class="shadow" #shadow></div>
    <article class="page container" #container>
      <div class="swiper-container" #swiperContainer>
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let item of tips; index as i">
            <section class="box" #box (click)=next($event)>
              <h1 #title>{{item.title}}</h1>
              <p #text>{{item.text}}<br>
                  <img #image src="{{item.img}}">
              </p>
              <div class="close" (click)=last($event)>&#xe90b;</div>
            </section>
          </div>
        </div>
        <div class="swiper-pagination" #panel></div>
      </div>
    </article>
  `,
  styleUrls: ['./tutorial.component.scss']
})
export class TutorialComponent implements AfterViewInit {

  @ViewChildren('box') boxes: QueryList<ElementRef>;
  @ViewChild('container') container: ElementRef;
  @ViewChild('panel') panel: ElementRef;
  @ViewChild('shadow') shadow: ElementRef;
  @ViewChild('swiperContainer') swiperContainer: ElementRef;

  swiper: any;

  firstTime = true;

  tutorials: any[] = [
    [
      {title: 'getting started', text: 'Drag & drop parts to build your Aria', img: 'assets/img/tutorial/tutorial-1-drag-drop.png'},
      {title: 'getting started', text: 'While dragging parts, use the arrow keys or scroll wheel to rotate', img: 'assets/img/tutorial/tutorial-2-rotate.png'},
      {title: 'getting started', text: 'When your build is complete, tap view in 3D to start customising your Aria in 3D', img: 'assets/img/tutorial/tutorial-3-view-3d.png'}
    ],
    [
      {title: 'customise aria', text: 'Use the bottom buttons to change colors and specify arms & legs', img: 'assets/img/tutorial/tutorial-4-view-3d.png'},
      {title: 'change view', text: 'Tap the perspective button at the top left to toggle between perspectives', img: 'assets/img/tutorial/tutorial-5-view-3d.png'},
      {title: 'take a photo', text: 'Tap the camera icon at the top left to download a photo of your Aria', img: 'assets/img/tutorial/tutorial-6-view-3d.png'},
      {title: 'product resources', text: 'Tap the document icon at the top left to download product resources', img: 'assets/img/tutorial/tutorial-7-view-3d.png'}
    ]
  ];

  tips: any[];

  prevSlideNum = 0;

  ngAfterViewInit() {

    window.addEventListener('resize', () => { this.positionPanel(); });

    this.swiper = new Swiper(this.swiperContainer.nativeElement, {
      pagination: { el: '.swiper-pagination', clickable: true },
      mousewheel: true,
      direction: 'horizontal'
    });

    this.swiper.on('transitionEnd', () => {
      if (this.swiper.isEnd && this.prevSlideNum === this.tips.length - 1) { this.last(); }
      this.prevSlideNum = this.swiper.activeIndex;
    });

  }

  constructor(private appRef: ApplicationRef) {/* tick */ }

  public positionPanel() {

    const boxes = this.boxes.toArray();

    const offset = 0;
    if(!this.container || !boxes[0]) return;
    const containerHalfHeight = this.container.nativeElement.offsetHeight / 2;
    const boxHalfHeight = boxes[0].nativeElement.offsetHeight / 2;

    this.panel.nativeElement.style.top = containerHalfHeight + boxHalfHeight + offset + 'px';
  }

  public next(event): void {

    if (this.swiper.isEnd) {
      this.last(event);
      return;
    }
    this.swiper.slideNext();
  }

  public last(event?) {

    if (event !== undefined) {
        event.stopPropagation();
      }

    this.firstTime = false;

    const that = this;

    TweenLite.to(this.shadow.nativeElement, 0.5, {ease: Power1.easeOut, opacity: 0, onComplete() {
      that.shadow.nativeElement.style.display = 'none';
    }});

    TweenLite.to(that.container.nativeElement, 0.5, {ease: Power1.easeOut, top: '100%'});
  }

  public run(num): void {

    if (!this.firstTime) { return; }

    this.tips = this.tutorials[num];

    this.appRef.tick();

    this.swiper.slideTo(0, 0);

    this.swiper.update();

    const that = this;

    that.shadow.nativeElement.style.display = 'block';

    TweenLite.to(this.shadow.nativeElement, 0.5, {ease: Power1.easeOut, opacity: .2});

    TweenLite.to(this.container.nativeElement, 0.5, {ease: Power1.easeOut, top: 0});

    this.positionPanel();
  }
}

