import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URL, APP_ID } from '../appConfig';

@Injectable()
export class HttpService {

  constructor(private http: HttpClient) {}

  post(request, data: any) {

    const url = API_URL + request + APP_ID;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(url, data); // JSON.stringify(data), httpOptions);
  }
}

@Injectable()
export class HttpServiceV2 {

  constructor(private http: HttpClient) {}

  post(data: any) {

    const url = 'https://api.inhaabit.com/clients/street-furniture/parts-list' + APP_ID;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(url, data); // JSON.stringify(data), httpOptions);
  }
}
