import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { TweenLite, Power1, Power2, Power3 } from 'gsap/all';
import cssVars from 'css-vars-ponyfill';

@Component({
  selector: 'app-home-greeting',
  template: `
    <!--article class="page" #page-->
      <section class="box" #box>
        <h1>build aria</h1>
        <p class="hero-text">Welcome to the Build Aria configurator.<br>
          Here you can design your own Aria bench by<br>
          choosing shapes, colours, arms and legs.
        </p>
        <nav class="footer">
          <ul>
            <li class="button build" (click)=build()>start building</li>
            <!--li class="button" (click)=about()>about Aria</li-->
          </ul>
        </nav>
      </section>
    <!--/article-->
  `,
  styles: [`
    .hero-text {
      text-align:center;
      margin-top:15px;
      margin-bottom:5px;
    }
    .page {
      background-color: transparent;
      top: 0;
    }
    .box {
      position: fixed;
      width: 630px;
      height: 368px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 60px 50px;
      background-color: rgba(255, 255, 255, 0.8);
      color: var(--dark-charcoal);
      border-radius: 8px;
      box-sizing: border-box;
      z-index: 10;
    }
    h1 {
      text-align: center;
      font-size: 62px;
      letter-spacing:-1px;
      font-weight: bold;
    }
    p {
      margin-top: 5px;
      text-align: left;
      font-size: 19px;
    }
    .button {
      width: 210px;
      height: 53px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 19px;
      font-weight: bold;
      box-sizing: border-box;
      text-align: center;
    }
    .build {
      /*margin-right: 40px;*/
      background-color: var(--btn-pink);
    }
    .build:hover {
      border: solid 3px var(--btn-pink);
      color: var(--btn-pink);
      background-color: white; 
    }
    nav {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 70px;
      line-height: 0;
      text-align: center;
    }
    ul { list-style: none; overflow: hidden; display: inline-block; }
    li {
      cursor: pointer;
      outline: none;
    }
    .footer li {
      float: left;
    }
  `]
})

export class GreetingComponent {

  @ViewChild('box') box: ElementRef;
  @Output() startBuilding = new EventEmitter<void>();
  @Output() showAbout = new EventEmitter<void>();

  constructor() { cssVars(); }

  public show(): void {

    TweenLite.from(this.box.nativeElement, 1.5, { ease: Power2.easeOut, opacity: 0 }).delay(1);
  }

  public build(): void {

    this.startBuilding.emit();
  }

  public about(): void {

    this.showAbout.emit();
  }
}
