import { Component, ViewChild, ElementRef, Output, EventEmitter, Renderer2, OnInit } from '@angular/core';
import { TweenLite, Power3 } from 'gsap/all';
import { ConnectorComponent } from './connector/connector.component';
import { Cfg2d } from './connector/configurator2d/js/Cfg2d';
import { device } from './connector/view3d/configurator3d/js/utils';
import { parts } from './connector/configurator2d/js/data';
import { loadData } from './connector/configurator2d/js/loader';
import { initSequences } from './initSequences';
import { touchController } from './touchController';
import cssVars from 'css-vars-ponyfill';
import { path } from '../../assets/js/urls';

@Component({
  selector: 'app-chooser',
  template: `
    <article class="page" #page>
      <section class="header">
        <div class="arrow" (click)=hide()>&#xe907;</div>
        <span class="title">choose starting configuration</span>
      </section>
      <section class="main">
          <div class="content" #content></div>
      </section>
    </article>
    <app-connector (showConnector)="show()"></app-connector>
  `,
  styles: [`
    . {
      background-color: #fff;
      margin: 0 30px;
      height: 370px;
      width: 400px;
      position: relative;
    }
    .set-image {
      width: 400px;
      cursor: pointer;
    }
    .content {
      padding: 0 60px;
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      align-content: flex-start;
      flex-basis: 0%;
      overflow: hidden;  /* это трюк для Edge */
    }
    .set:nth-last-child(-n+4) {
      margin-bottom: 160px;
    }
    .header {
      background-color: #fff;
    }
    .title {
      line-height: 100px;
      color: var(--dark-charcoal);
      font-size: 1.8rem;
      font-weight: bold;
    }
    .main {
      flex-shrink: 1;
      flex-direction: column;
      width: 100%;
      overflow: auto;
      
      -webkit-overflow-scrolling: touch;
    }
    .page {
      left: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      z-index: 10;
    }
    .arrow {
      position: absolute;
      width: 50px;
      height: 50px;
      color: var(--light-grey-hover);
      line-height: 50px;
      font-family: "Icons";
      font-size: 25px;
      left: 30px;
      top: 30px;
      border-radius: 4px;
      cursor: pointer;
    }
    .arrow:hover { color: var(--charcoal); font-size: 28px; }
  `]
})
export class ChooserComponent implements OnInit {

  @ViewChild('page') page: ElementRef;
  @ViewChild('content') content: ElementRef;
  @Output() showChooser = new EventEmitter<void>();
  @ViewChild(ConnectorComponent) connector;

  currentDevice: string = device();

  constructor(private renderer: Renderer2) { cssVars(); }

  ngOnInit() {

    touchController(this.content.nativeElement);

    loadData(path).then(() => {
      for (const initialSequence of initSequences) {
        const set = this.renderer.createElement('div');

        // if (this.currentDevice !== 'DESKTOP') {
          set.addEventListener('click', () => {
            this.connector.show();
            this.connector.initialSequence = {codes: initialSequence.codes, angle: initialSequence.angle};
          });
        // }

        this.renderer.appendChild(this.content.nativeElement, set);
        this.renderer.addClass(set, 'set');

        const image = this.renderer.createElement('img');
        // image.style.width = '400px';
        image.src = `https://inhaabit.com/demo/aria/starting/${initialSequence.codes}.png`;
        this.renderer.addClass(image, 'set-image');
        this.renderer.appendChild(set, image);

        const callbacks = {

          msClick: (link) => {
            this.connector.initialSequence = { codes: link.chain.getCodes(), angle: link.chain.getAngle() };
            this.connector.show();
            // this.showConnector();
          },
          msDown: (link, event) => {
          },
          endDrag: (link) => {
          }
        };

        const set2 = this.renderer.createElement('div');
        const cfg2d = new Cfg2d(document, set2, callbacks, parts, initialSequence.codes, initialSequence.angle, false, false);
      }
    });
  }

  public show(): void {

    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: 0 });
  }

  public hide(): void {

    this.showChooser.emit();
    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '100%' });
  }

  public showConnector(): void {

    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '-50%' });
  }
}
