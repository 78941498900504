import { Component, ViewChild, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';
import { TweenLite, Power3 } from 'gsap/all';
import { HttpService } from '../http.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
  providers: [HttpService]
})
export class ResourcesComponent implements OnInit {

  @ViewChild('page') page: ElementRef;
  @ViewChild('overlay') overlay: ElementRef;
  @Output() actionType = new EventEmitter<void>();

  codes: string;
  message: string;

  constructor(private httpService: HttpService) {}

  ngOnInit() {
    this.page.nativeElement.addEventListener('keydown', (event) => { event.stopPropagation(); }, true);
  }

  public show(): void {
    this.page.nativeElement.style.display = 'block';
    TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power3.easeOut,
      opacity: 1
    });
  }

  public hide(): void {

    TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power3.easeOut,
      opacity: 0,
      onComplete: () => { this.page.nativeElement.style.display = 'none'; }
    });
  }

  public getGuide() {
    this.actionType.emit();
  }
}
