import { Component, ViewChild, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';
import { NgForm} from '@angular/forms';
import { TweenLite, Power3 } from 'gsap/all';
import { HttpService } from '../http.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  providers: [HttpService]
})
export class DetailsComponent implements OnInit {

  @ViewChild('page') page: ElementRef;
  @ViewChild('state') state: ElementRef;
  @ViewChild('overlay') overlay: ElementRef;
  @Output() continue = new EventEmitter<string>();

  codes: string;
  message: string;

  constructor(private httpService: HttpService) {}

  ngOnInit() {
    this.state.nativeElement.addEventListener('change', event => {
      this.overlay.nativeElement.style.display = 'none';
    }, false);

    this.page.nativeElement.addEventListener('keydown', (event) => { event.stopPropagation(); }, true);
  }

  public show(): void {
    this.page.nativeElement.style.display = 'block';
    TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power3.easeOut,
      opacity: 1
    });
  }

  public hide(): void {

    TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power3.easeOut,
      opacity: 0,
      onComplete: () => { this.page.nativeElement.style.display = 'none'; }
    });
  }

  submit(form: NgForm) {
    // console.log('SUBMIT');
    this.hide();
    this.continue.emit(JSON.stringify(form.value));
    /*this.httpService.post('contact', form.value)
      .subscribe( (data) => {
          console.log(data);
        }, error => {
          console.error(error);
      });*/
  }
}
