import { Component, ViewChild, Output, EventEmitter, ElementRef, OnInit } from '@angular/core';
import { NgForm} from '@angular/forms';
import { TweenLite, Power1, Power3 } from 'gsap/all';
import { HttpService } from '../../http.service';
import { AlertComponent } from '../../alert/alert.component';

@Component({
  selector: 'app-home-login',
  template: `
    <div class="shadow" #shadow></div>
    <article class="page" #page>
      <div class="box">
        <!--div class="logo"></div-->
        <div class="title">staff login</div>
        <div class="close" (click)=hide()>&#xe90b;</div>
        <form #form="ngForm" novalidate>
          <input type="text" name="email" placeholder="Your email (required)" [value]="email" ngModel required>
          <input type="password" name="password" placeholder="Your password (required)" [value]="password" ngModel required>
          <button (click)="submit(form)" [disabled]="form.invalid">send</button>
        </form>
      </div>
    </article>
    <app-alert></app-alert>
  `,
  styles: [`
    .page {
      background-color: transparent;
      top: 100%;
      z-index: 10;
    }
    .box {
      position: absolute;
      width: 250px;
      height: 200px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 35px 30px;
      text-align: center;
      background-color: #fff;
      border-radius: 3%;
      box-shadow: 0 0 7px rgba(168,160,150,0.5);
    }
    .shadow {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0;
      display: none;
      z-index: 10;
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Icons";
      font-size: 12px;
      border-radius: 100%;
      color: var(--dark-charcoal);
      cursor: pointer;
    }
    .close:hover { background-color: #FF252A; color: #fff; }
    button {
      background-color: var(--btn-pink);
      border: none;
      color: white;
      padding: 10px 60px;
      margin-top: 25px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: normal;
      cursor: pointer;
    }
    button,
    button:active,
    button:focus {
      outline: none;
    }
    button:hover {
      outline: solid 3px var(--btn-pink);
      color: var(--btn-pink);
      background-color: white;
    }
    .title {
      font-size: 1.6rem;
      font-weight: bold;
      color: var(--dark-charcoal);
      margin-bottom: 20px;
    }
    .logo {
      width: 210px;
      height: 62px;
      background-image: url("assets/img/ui/logo-dark.png");
      background-size: contain;
    }
    button:disabled {
      outline: none;
      color: white;
      background-color: var(--btn-light-pink);
    }
    input[type="text"], input[type="password"] {
      background-color: transparent;
      height: 35px;
      width: 100%;
      margin-top: 15px;
      padding: 0 5px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 1.2rem;
      line-height: normal;
      border: solid 1px #AE968D;
      border-radius: 3px;
      text-shadow: none;
      display: block;
      box-sizing: border-box;
    }
  `],
   providers: [HttpService]
})

export class LoginComponent implements OnInit {

  @ViewChild('page') page: ElementRef;
  @ViewChild('shadow') shadow: ElementRef;
  @ViewChild(AlertComponent) alert;
  @Output() respond = new EventEmitter<any>();

  email: string;
  password: string;

  constructor(private httpService: HttpService) {}

  ngOnInit() {
    this.page.nativeElement.addEventListener('keydown', (event) => { event.stopPropagation(); }, true);
  }

  public show(): void {

    this.shadow.nativeElement.style.display = 'block';
    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, top: 0 });
    TweenLite.to(this.shadow.nativeElement, 0.5, {ease: Power1.easeOut, opacity: .2});
  }

  hide(): void {

    TweenLite.to(this.page.nativeElement, 0.5, {
      ease: Power1.easeOut,
      top: '100%'
    });
    TweenLite.to(this.shadow.nativeElement, 0.5, {ease: Power1.easeOut, opacity: 0, onComplete: () => {
      this.shadow.nativeElement.style.display = 'none';
    }});
  }

  submit(form: NgForm) {

    console.log(form.value);
    this.httpService.post('login', form.value)
      .subscribe( (data) => {
        this.hide();
        this.respond.emit(data);
      }, error => {
        this.showAlert();
        console.error(error);
      });
  }

  showAlert(): void {

    this.alert.message = {
      title: 'Error',
      text: 'Username / password incorrect'
    };
    this.alert.show();
  }
}
