import { Component, ViewChild, ViewChildren, QueryList, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';
import { TweenLite, Power3, Expo } from 'gsap/all';
import { parts } from '../configurator2d/js/data';
import { Plan } from '../configurator2d/js/Plan';
import { Cfg3d } from './configurator3d/js/Cfg3d';
import { LEGS, ARMS } from './configurator3d/js/types';
import { ContactComponent } from '../../../contact/contact.component';
import { ResourcesComponent } from '../../../resources/resources.component';
import { DetailsComponent } from '../../../details/details.component';
import { TutorialComponent } from '../../tutorial/tutorial.component';
import { Contact3dComponent } from './contact3d/contact3d.component';
import { EmailComponent } from './email/email.component';
import { QuoteComponent } from './quote/quote.component';
import { AlertComponent } from '../../../alert/alert.component';
import { configuration } from './configuration.data';
import { HttpService, HttpServiceV2 } from '../../../http.service';
import { isIE, formatDate, formatDayMonthYear, loadPdf } from './configurator3d/js/utils';
import { partData, getUniqItems } from './configurator3d/js/part.data';
import cssVars from 'css-vars-ponyfill';

enum Mode {
  Email,
  Quote
}
enum Step {
  Init,
  Take
}

@Component({
  selector: 'app-view3d',
  template: `
    <article class="page" #page (touchstart)=hideTypeView($event) (mousedown)=hideTypeView($event)>
      <div class="flash" #flash></div>
      <span class="name"></span>
      <div class="arrow" (touchend)=hide() (click)=hide() #arrow>&#xe907;</div>
      <div class="view-type" #viewType></div>
      <div class="left-spots" #leftSpots>
        <div class="spot options" data-title="Get&nbsp;Technical&nbsp;Sheet" (touchend)=showDetails() (click)=showDetails()></div>
        <div class="spot view" data-title="Change&nbsp;View" (touchend)=showNextView() (click)=showNextView() #nextView></div>
        <!--div class="spot photo" title="Take a photo" (touchend)=captureScene() (click)=captureScene()></div-->
        <div class="spot photo" data-title="Save&nbsp;Image" (touchend)=takePhotoImmediately() (click)=takePhotoImmediately()></div>
        <!--div *ngIf="!forServer&&!take" class="spot" title="Customise arms & legs" (touchend)=chooseComponents($event) (click)=chooseComponents($event)>
          &#xe901;</div-->
        <!--div *ngIf="!forServer&&!take" class="spot options" title="Options" (touchend)=showContact3d() (click)=showContact3d()></div-->
      </div>
      <nav class="footer" #footer>
        <div class="footer-button" (touchend)=showBattenColorPicker($event) (click)=showBattenColorPicker($event)>batten colour</div>
        <div class="footer-button" (touchend)=showFrameColorPicker($event) (click)=showFrameColorPicker($event)>frame colour</div>
        <div class="footer-button" (touchend)=chooseComponents($event) (click)=chooseComponents($event)>arm and leg</div>
      </nav>
      <div class="bottom-spots" #bottomSpots>
        <div class="spot contact" data-title="Enquire&nbsp;Now" (touchend)=showContact() (click)=showContact()></div>
        <div class="spot help" data-title="Help" (touchend)=help() (click)=help()></div>
      </div>
      <div class="spot photo bottom-place" data-title="Take&nbsp;a&nbsp;photo" (touchend)=captureScene() (click)=captureScene() #photoBtn></div>
    </article>
    <app-tutorial></app-tutorial>
    <app-contact></app-contact>
    <app-details (continue)="generateTechnicalSheet($event)"></app-details>
    <!--app-resources (actionType)="generateTechnicalSheet()"></app-resources-->
    <app-view3d-contact3d (actionType)="action($event)"></app-view3d-contact3d>
    <app-alert (actionType)="toEmailMode()"></app-alert>
    <app-alert></app-alert>
    <app-alert (actionType)="toQuoteMode()"></app-alert>
    <app-alert (actionType)="saveScene()" (closeType)="showPanels()"></app-alert>
    <app-alert (actionType)="openDocument()"></app-alert>
    <app-alert (actionType)="takePhoto()" (closeType)="showPanels()"></app-alert>
    <app-alert (actionType)="replacePart()"></app-alert>
    <app-alert (actionType)="openPdf()"></app-alert>
    <app-view3d-email (request)="request($event)"></app-view3d-email>
    <app-view3d-quote (generate)="generate()"></app-view3d-quote>
  `,
  styles: [`
    .page {
      left: 100%;
      background-color: #fff;
      z-index: 10;
    }
    .flash {
      position: absolute;
      display: none;
      width: 100%;
      height: 100%;
      background-color: #fff;
      opacity: 0;
    }
    .arrow {
      position: absolute;
      width: 50px;
      height: 50px;
      color: var(--light-grey-hover);
      line-height: 50px;
      font-family: "Icons";
      font-size: 25px;
      left: 30px;
      top: 30px;
      border-radius: 4px;
      cursor: pointer;
    }
    .arrow:hover { color: var(--charcoal); font-size: 28px; }
    .view-type { 
      top: 20px;
      color: var(--dark-charcoal); 
      font-size: 28px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .spot {
      position: relative;
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 25px;
      border-radius: 100%;
      font-family: "Icons";
      color: var(--light-grey-hover);
      cursor: pointer;
    }
    .spot:hover { background-color: var(--charcoal); color: #000; }
    .left-spots {
      left: 28px;
      top: 102px;
      list-style: none;
      position: absolute;
      display: flex;
      flex-direction: column;
    }
    .view {
      margin-bottom: 7px;
      background: no-repeat center/100% url("assets/img/ui/set-view-btn.png") var(--light-grey);
    }
    .view:hover {
      background: no-repeat center/100% url("assets/img/ui/set-view-btn_.png") var(--charcoal);
    }
    .photo {
       background: no-repeat center/100% url("assets/img/ui/save-photo-icon.png") var(--light-grey);
    }
    .photo:hover {
      background: no-repeat center/100% url("assets/img/ui/save-photo-icon_.png") var(--charcoal);
    }
    .options {
      margin-bottom: 7px;
      background: no-repeat center/100% url("assets/img/ui/technical-sheet-icon.png") var(--btn-pink);
    }
    .options:hover {
      background: no-repeat center/100% url("assets/img/ui/technical-sheet-icon_.png") var(--btn-pink-hover);
    }
    .bottom-spots {
      left: 28px;
      bottom: 27px;
      position: absolute;
      display: flex;
      flex-direction: row;
    }
    .bottom-place {
      position: absolute;
      bottom: 27px;
      left: 50%;
      transform: translateX(-50%);
      visibility: hidden;
    }
    .help {
      margin-left: 8px;
      background: no-repeat center/100% url("assets/img/ui/help-icon.png") var(--light-grey);
    }
    .help:hover {
      background: no-repeat center/100% url("assets/img/ui/help-icon_.png") var(--charcoal);
    }
    .contact {
      background: no-repeat center/100% url("assets/img/ui/contact-icon.png") var(--light-grey);
    }
    .contact:hover {
      background: no-repeat center/100% url("assets/img/ui/contact-icon_.png") var(--charcoal);
    }
    .footer {
      position: absolute;
      display: flex;
      left: 50%;
      bottom: 31px;
      transform: translateX(-50%);
    }
    .footer-button {
      width: 180px;
      height: 44px;
      margin: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #fff;
      background: #A4A3A2;
      cursor: pointer;
    }
    .footer-button:hover {
      background-color: var(--charcoal);
    }
  `],
  providers: [HttpService, HttpServiceV2]
})
export class View3dComponent implements OnInit {
  @ViewChild('page') page: ElementRef;
  @ViewChild(ContactComponent) contact;
  @ViewChild(ResourcesComponent) resources;
  @ViewChild(DetailsComponent) details;
  @ViewChild(Contact3dComponent) contact3d;
  @ViewChildren(AlertComponent) alerts: QueryList<AlertComponent>;
  @ViewChild(TutorialComponent) tutorial;
  @ViewChild(EmailComponent) email;
  @ViewChild(QuoteComponent) quote;
  @ViewChild('flash') flash;
  @ViewChild('nextView') nextView: ElementRef;
  @ViewChild('viewType') viewType: ElementRef;
  @ViewChild('arrow') arrow: ElementRef;
  @ViewChild('leftSpots') leftSpots: ElementRef;
  @ViewChild('bottomSpots') bottomSpots: ElementRef;
  @ViewChild('footer') footer: ElementRef;
  @ViewChild('photoBtn') photoBtn: ElementRef;
  @Output() showConnector = new EventEmitter<void>();

  promiseCfg3d: any;
  cfg3d: any;
  cfg2d: any;

  sequence: any[];
  forServer = false;
  mode: Mode;
  step: Step = Step.Init;
  take = false;

  pdf: any;
  ie = false;

  indexView = 0;

  public pdfData: any = null;

  constructor(private httpService: HttpService, private httpServiceV2: HttpServiceV2) {
    cssVars();
    this.ie = isIE();
  }

  ngOnInit() {
    let firstTime = true;
    this.promiseCfg3d = new Cfg3d(this.page.nativeElement, {data: ''});
    this.promiseCfg3d.then((that) => { this.cfg3d = that; this.cfg3d.onReplace(() => {
      if(!firstTime) {
        this.replacePart();
        return;
      }
      const alert = this.alerts.toArray()[6];
      alert.message.title = 'please note';
      alert.message.text = 'Your current arm settings will be replaced.';
      alert.message.button = 'continue';
      alert.show();
      firstTime = false;
      });
    });
    localStorage.setItem('arms', 'NO');
    localStorage.setItem('legs', 'STRAIGHT');

  }

  public show(): void {
    const lType = localStorage.getItem('legs');
    const aType = localStorage.getItem('arms');

    this.promiseCfg3d.then((that) => { that.setSequence(this.sequence, this.sequence[0].angle, LEGS[lType], ARMS[aType]); });

    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: 0, onComplete: () => {
      // this.tutorial.run(1);
    }});
  }

  public chooseComponents(event): void {

    event.stopPropagation();

    this.promiseCfg3d.then((that) => { that.showChooserPanel(); });
  }

  public showBattenColorPicker(event): void {
    event.stopPropagation();
    this.promiseCfg3d.then((that) => { that.showColorPicker('battens'); });
  }

  public showFrameColorPicker(event): void {
    event.stopPropagation();
    this.promiseCfg3d.then((that) => { that.showColorPicker('frame'); });
  }

  public captureScene(): void {

    this.hidePanels();

    if (this.forServer) {

      this.shine();

      configuration.image3D = this.cfg3d.captureScene();

      switch (this.mode) {

        case Mode.Email:
          this.email.codes = configuration.partsCode;
          this.email.image = configuration.image3D;
          setTimeout(() => { this.email.show(); }, 400);
          break;

        case Mode.Quote:
          setTimeout(() => { this.sendData(); }, 400);
          break;

      }
      this.forServer = false;

    } else {

      switch (this.step) {

        case Step.Init:

          const alert = this.alerts.toArray()[5];
          alert.message.title = 'take a photo';
          alert.message.text = 'Position your Aria to take a photo. Use mouse wheel to zoom.';
          alert.message.button = 'continue';
          alert.show();
          break;

        case Step.Take:

          this.shine();

          setTimeout(() => {

              const alert = this.alerts.toArray()[3];
              alert.message.title = 'take a photo';
              alert.message.text = 'Your photo will now be saved to your device.';
              alert.message.button = 'save photo';
              alert.show();
              this.step = Step.Init;
              this.take = false;
            }, 400);
          break;
      }
    }
    this.cfg3d.startEdit();
  }

  private hidePanels(): void {
    this.cfg3d.stopHighlighter();
    this.cfg3d.hidePanels();
    this.arrow.nativeElement.style.visibility = 'hidden';
    this.leftSpots.nativeElement.style.visibility = 'hidden';
    this.bottomSpots.nativeElement.style.visibility = 'hidden';
    this.footer.nativeElement.style.visibility = 'hidden';
    this.photoBtn.nativeElement.style.visibility = 'visible';
    const elements = document.getElementsByClassName('c3d_aside') as any;
    for(const el of elements) {
      el.style.visibility = 'hidden';
    }
  }

  public showPanels(): void {
    this.cfg3d.startHighlighter();
    this.arrow.nativeElement.style.visibility = 'visible';
    this.leftSpots.nativeElement.style.visibility = 'visible';
    this.bottomSpots.nativeElement.style.visibility = 'visible';
    this.footer.nativeElement.style.visibility = 'visible';
    this.photoBtn.nativeElement.style.visibility = 'hidden';
    const elements = document.getElementsByClassName('c3d_aside') as any;
    for(const el of elements) {
      el.style.visibility = 'visible';
    }
  }

  public takePhoto() {
    this.take = true;
    this.step = Step.Take;
  }

  public takePhotoImmediately() {
    this.shine();

    setTimeout(() => {
      configuration.partsCode = this.cfg3d.getSequence();
      this.cfg3d.saveScene(configuration.partsCode);
    }, 100);
  }

  public saveScene() {
    configuration.partsCode = this.cfg3d.getSequence();
    this.cfg3d.saveScene(configuration.partsCode);
    this.showPanels();
  }

  public sendData() {
    const alert = this.alerts.toArray()[1];
    alert.message.title = 'generate quote';
    alert.message.text = 'Please wait as we generate your quote.';
    alert.message.button = '';
    alert.show();

    const formData = new FormData();
    Object.entries(configuration).forEach(
      ([key, value]: any[]) => {
        formData.append(key, value);
      });

    // formData.set('powerMePositions', JSON.stringify(configuration.powerMePositions));

    this.httpService.post('quote', formData)
      .subscribe( (pdf: any) => {
        this.pdf = pdf;
        alert.hide();
        const openAlert = this.alerts.toArray()[4];
        openAlert.message.title = 'generate quote';
        openAlert.message.text = 'Your quote is ready.';
        openAlert.message.button = 'download';
        openAlert.show();

      }, error => {
        alert.hide();
        alert.message.title = 'sorry';
        alert.message.text = 'We encountered an issue.';
        alert.message.button = 'continue';
        alert.show();
        console.error(error);
      });
  }

  public showContact() {

    this.contact.codes = this.cfg3d.getSequence();

    this.cfg3d.hidePanels();

    // this.contact.message = 'Price: $' + this.cfg3d.getPrice() + ' (without wifi)\n\nDetails:\n' +  this.cfg3d.getDetails();
    this.contact.show();
  }

  public showDetails(): void {
     this.action(null);
     this.cfg3d.hidePanels();
     this.details.show();
  }

  public showQuote() {

    const plan = new Plan(document, this.cfg2d, parts);
    plan.getPromise().then((image) => {
      configuration.image2D = image;
    });

    configuration.dimensions = this.cfg2d.getRealSize().text;
    configuration.powerMePositions = this.cfg2d.getPMPoses();

    this.quote.show();
  }

  public async generateTechnicalSheet(event) {
    const data = JSON.parse(event);
    const alert0 = this.alerts.toArray()[1];
    alert0.message.title = 'technical sheet';
    alert0.message.text = `Please wait as we generate your technical sheet`;
    alert0.show();


    const config = {
      date: formatDate(new Date()),
      configuration: null,
      'dimensions-inch': '21 1/16\"W x 31 1/8\"H x 18\"L',
      'dimensions-mm': '535W x 790H x 320L (mm)',
      parts: null,
      name: data.name,
      email: data.email,
      phone: data.phone,
      state: data.state
    };

    const body = {
      data: config,
      image0: null,
      image1: null
    };

    this.cfg3d.generateSceneData();
    const plan = new Plan(document, this.cfg2d, parts);

    body.image0 = await plan.getPromise();
    body.image1 = this.cfg3d.captureScene();

    const dimensions = this.cfg2d.getRealSize().text;
    const dimsArray = dimensions.split(' / ');
    config['dimensions-mm'] = dimsArray[0];
    config['dimensions-inch'] = dimsArray[1];
    config['pdfName'] = this.getPdfName();
    config.parts = getUniqItems();
    config.configuration = this.cfg3d.getSequence();


    this.httpServiceV2.post(body)
      .subscribe( (data: any) => {
 console.log(body);
        // this.pdfData = data;
        // alert0.hide();
        /* const alert1 = this.alerts.toArray()[7];
        alert1.message.title = 'technical sheet';
        alert1.message.text = `Download your technical sheet below`;
        alert1.message.button = 'download';
        alert1.show(); */
        loadPdf(data.url).then((blob) => {
          alert0.hide();
          this.openPdf(URL.createObjectURL(blob));
        });
      }, error => {
        console.error(error);
      });
  }

  private getPdfName() {
    const codes = this.cfg2d.chain.getCodes();
    const date = formatDayMonthYear(new Date());
    return 'CMA-' + codes + ' ' + date + '.pdf';
  }

  public openPdf(href) {
    // window.open(this.pdfData.url, '_blank');
    const link = document.createElement( 'a' );
    link.href = href; // 'this.pdfData.url';

    link.download = this.getPdfName();
    link.dispatchEvent(new MouseEvent('click'));
  }

  public showContact3d() {

    this.promiseCfg3d.then((that) => {
      that.hidePanels();
      this.contact3d.show();
    });
  }

  hide(): void {

    this.showConnector.emit();
    TweenLite.to(this.page.nativeElement, 0.5, { ease: Power3.easeOut, left: '100%' });
  }

  public action(event) {
    const styles = this.cfg3d.getStyles();
    configuration.arms = this.cfg3d.getAmountOfArms();
    configuration.partsCode = this.cfg3d.getSequence();
    configuration.battensFinish = styles.battensFinish;
    configuration.frameFinish = styles.frameFinish;
    configuration.powerMeFinish = styles.powerMeFinish;
    configuration.price = this.cfg3d.getPrice();

    switch (event) {

      case 'emailConfig':
        const alert = this.alerts.toArray()[0];
        alert.message.title = 'email your configuration';
        alert.message.text = `Email a photo of your configuration and parts list. Get started by taking a photo.`;
        alert.message.button = 'take photo';
        alert.show();
        break;

      case 'getInTouch':
        this.showContact();
        break;

      case 'createQuote':
        if (this.ie) {
          this.detectedIE();
        } else {
          this.showQuote();
        }
        break;
    }
  }

  public request(event) {

    const alert = this.alerts.toArray()[1];

    switch (event) {

      case 'success':
        alert.message.title = 'configuration saved';
        alert.message.text = 'Your configuration has been emailed to you.';
        alert.message.button = 'continue';
        alert.show();
        break;

      case 'error':
        alert.message.title = 'Error';
        alert.message.text = 'Something went wrong.';
        alert.message.button = 'continue';
        alert.show();
        break;
    }
  }

  public openDocument() {

    window.open(this.pdf.url,'_blank');
    // wnd.location = pdf.url;
  }

  public toEmailMode() {

    this.cfg3d.stopEdit();

    this.forServer = true;
    this.mode = Mode.Email;
  }

  public toQuoteMode() {

    this.cfg3d.stopEdit();

    this.forServer = true;
    this.mode = Mode.Quote;
  }

  public replacePart() {

    this.cfg3d.replacePart();
  }

  public generate() {

    const alert = this.alerts.toArray()[2];
    alert.message.title = 'generate quote';
    alert.message.text = 'Take a photo of your Aria to add it to your quote.  Use mouse wheel to zoom.';
    alert.message.button = 'take photo';
    alert.show();
  }

  public detectedIE() {

    const alert = this.alerts.toArray()[1];
    alert.message.title = 'outdated browser';
    alert.message.text = 'Please use a <a href="https://www.google.com/chrome/">modern browser</a> to generate a quote.</a>';
    alert.message.button = 'close';
    alert.show();
  }

  private shine(): void {

    this.flash.nativeElement.style.display = 'block';

    TweenLite.to(this.flash.nativeElement, 0.2, {
      ease: Expo.easeInOut,
      opacity: 0.7,
      onComplete: () => {
        this.flash.nativeElement.style.display = 'none';
      }
    });
  }

  public help(): void {
    this.tutorial.firstTime = true;
    this.tutorial.run(1);
  }

  public close() {
    this.cfg3d.startEdit();
  }

  public showResources(): void {
    this.resources.show();
  }

  public showNextView(): void {

    this.viewType.nativeElement.style.opacity = 1;

    switch(this.indexView) {
      case 0: this.cfg3d.setTopView();
        this.nextView.nativeElement.title = 'Side View';
        this.viewType.nativeElement.textContent = 'top view';
        break;
      case 1: this.cfg3d.setLeftView();
        this.nextView.nativeElement.title = 'Front View';
        this.viewType.nativeElement.textContent = 'side view';
        break;
      case 2: this.cfg3d.setFrontView();
        this.nextView.nativeElement.title = 'Perspective View';
        this.viewType.nativeElement.textContent = 'front view';
        break;
      case 3: this.cfg3d.setPerspectiveView();
        this.nextView.nativeElement.title = 'Top View';
        this.viewType.nativeElement.textContent = 'perspective view';
        break;
    }
    this.indexView++;
    this.indexView = this.indexView > 3 ? 0 : this.indexView;
  }

  public hideTypeView(event?): void {
    if(event.target === this.nextView.nativeElement) return;
    TweenLite.to(this.viewType.nativeElement, 0.3, {
      ease: Expo.easeInOut,
      opacity: 0,
    });
  }
}
